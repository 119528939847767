import { React, useEffect, useState } from 'react';
// import { Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './expiringContractsTable.scss';
import { Badge, Col, Container, Image, Table, Row } from 'react-bootstrap';
import moment from 'moment';
import { getEntityById } from '../../assets/apiBank';
import _ from 'underscore';

// COMPONENTS
import CommodityIcon from '../commodityIcon/CommodityIcon';
import EntityModal from '../entityModal/EntityModal';
import SpinnerLoader from '../spinnerLoader/SpinnerLoader';
import ToastLoader from '../toastLoader/ToastLoader';

const Loader = () => (
  <h3>Loading... <SpinnerLoader /></h3>
)

function ExpiringContractsTable() {
  const userObj = JSON.parse(localStorage.getItem('user'));
  const expContracts = JSON.parse(localStorage.getItem('contactData')).expiring_contracts;
  const [entities, setEntities] = useState(JSON.parse(localStorage.getItem('contactData')).entities);
  const [clickedEntity, setClickedEntity] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openEntityDetailsModal = clientId => {
    var entity = _.findWhere(entities, {id: clientId});

    setClickedEntity(entity);
    setShowModal(true);
  }

  const closeModal = (entityId) => {
    setClickedEntity('');
    setShowModal(false);
  }

  return (
    <>
      <EntityModal showModal={showModal} entity={clickedEntity} closeModal={entityId => closeModal(entityId)} />
      {
        expContracts.length > 0 ?
        <Table bordered hover size="sm" id="expiring-contracts-table" responsive>
          <thead>
            <tr>
              <th className="text-uppercase text-center">Legal Entity</th>
              <th className="text-uppercase text-center">Supplier</th>
              <th className="text-uppercase text-center">Utility</th>
              <th className="text-uppercase text-center">Commodity</th>
              <th className="text-uppercase text-center">State</th>
              <th className="text-uppercase text-center">Days Left</th>
              <th className="text-uppercase text-center">Start Date</th>
              <th className="text-uppercase text-center">End Date</th>
            </tr>
          </thead>
          <tbody>
            {
              expContracts.map(eC => (
                <tr key={eC.id} style={{backgroundColor: eC.days_left <= 30 ? '#f4cbcc' : (eC.days_left > 30 && eC.days_left <= 90 ? '#FFDC00' : '')}}>
                  <td style={{cursor: 'pointer'}} onClick={() => openEntityDetailsModal(eC.client_id)}>{eC.legal_entity}</td>
                  <td style={{verticalAlign: 'middle', textAlign: 'center'}}><Image src={"https://crm.goananta.com/" + eC.supplier_asset_path} /></td>
                  <td style={{verticalAlign: 'middle', textAlign: 'center'}}><Image src={"https://crm.goananta.com/" + eC.utility_asset_path} /></td>
                  <td style={{verticalAlign: 'middle', textAlign: 'center'}}><CommodityIcon commodity={eC.type.toLowerCase()} /></td>
                  <td>{eC.state_name}</td>
                  <td>{eC.days_left}</td>
                  <td>{moment(eC.start_date).format('MM/DD/YYYY')}</td>
                  <td>{moment(eC.end_date).format('MM/DD/YYYY')}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        : <Loader />
      }
    </>
  );
}

export default ExpiringContractsTable;
