import { React, useEffect, useState } from 'react';
import { Button, Container, Col, Form, Row } from 'react-bootstrap';
import { contactSupport } from '../../assets/apiBank';
import 'bootstrap/dist/css/bootstrap.css';
import './contactUsForm.scss';
import _ from 'underscore';

// Components
import SpinnerLoader from '../spinnerLoader/SpinnerLoader';

const Loader = () => (
  <h5 style={{float: 'right', marginTop: '1rem', color: '#2ECC40'}}>Sending...<SpinnerLoader /></h5>
)

function ContactUsForm() {
  const [userObj, setUserObj] = useState(JSON.parse(localStorage.getItem('user')));
  const [entities, setEntities] = useState(JSON.parse(localStorage.getItem('contactData')).entities);
  const [accounts, setAccounts] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [selectedSubject, setSelectedSubject] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const [showAccountSelect, setShowAccountSelect] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState('');

  // State parameters
  const [subjects, setSubjects] = useState([
    {
        id: 1,
        message: "I have a question concerning one of my accounts.",
    },
    {
        id: 2,
        message: "I am selling one of my properties.",
    },
    {
        id: 3,
        message: "I want to enroll in Ananta's tracking and monitoring program.",
    },
    {
        id: 99,
        message: "General",
    }
  ]);

  ///////////////
  // Functions //
  ///////////////
  const handleMessageChange = e => {
    setMessage(e.target.value);
  }

  const handleSubjectChange = e => {
    setSelectedSubject(_.findWhere(subjects, {id: parseInt(e.target.value)}));
  }

  const handleEntityChange = e => {
    const entityObj = _.findWhere(entities, {id: e.target.value});

    setSelectedEntity(entityObj);
    setAccounts(entityObj.accounts);
  }

  const handleAccountChange = e => {
    setSelectedAccount(_.findWhere(accounts, {id: e.target.value}));
  }

  const submitForm = e => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to send?")) {
        setShowLoader(true);

        contactSupport({
            userId: parseInt(userObj.id),
            email: userObj.email_id,
            subject: selectedSubject.message,
            message: message,
            entityId: parseInt(selectedEntity.id),
            accountId: parseInt(selectedAccount.id),
        }, response => {
            console.log('response', response);
            if (response.success) {
                setShowLoader(false);
                alert('Sent!');
            }
        });
    }
  }

  return (
    <div id="contact-us-form">
        <Form onSubmit={submitForm}>
          <Form.Group className="mb-3">
            <Form.Label>Subject *</Form.Label>
            <Form.Select className="subjectSelect" name="subject" onChange={handleSubjectChange}>
              <option></option>
              {
                subjects.map((subject, i) => (
                  <option key={i} value={subject.id}>{subject.message}</option>
                ))
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Legal Entity *</Form.Label>
            <Form.Select className="entitySelect" name="entity" onChange={handleEntityChange}>
              <option></option>
              {
                entities.map((entity, i) => (
                  <option key={i} value={entity.id}>{entity.legal_entity}</option>
                ))
              }
            </Form.Select>
          </Form.Group>

          {
              accounts.length > 0 &&
              <Form.Group className="mb-3">
                <Form.Label>Account *</Form.Label>
                <Form.Select className="accountSelect" name="account" onChange={handleAccountChange}>
                  <option></option>
                  {
                    accounts.map((account, i) => (
                      <option key={i} value={account.id}>{account.acct_number + ' - ' + account.account_full_address + ' (' + account.type + ')'}</option>
                    ))
                  }
                </Form.Select>
              </Form.Group>
          }

          <Form.Control as="textarea" rows="10" name="message" onChange={handleMessageChange} placeholder="Leave a message here" />

          <Button variant="primary" type="submit" style={{marginTop: '1rem'}}>
            Submit
          </Button>{showLoader && <Loader />}
        </Form>
    </div>
  );
}

export default ContactUsForm;
