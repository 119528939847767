import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

function ContainerCol({children, fluid}) {
  return (
    fluid ?
    <Container fluid>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
    :
    <Container>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  );
}

export default ContainerCol;
