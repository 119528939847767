import { React, useState, useEffect } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/login.scss';
import logo from '../ananta_logo_white.png';
import { getContactAffiliations } from '../assets/apiBank';
import { useNavigate } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

// THIS BLOCK ALLOWS THE USER TO CLICK ENTER TO SUBMIT SINCE IT'S A NORMAL BUTTON AND NOT A SUBMIT BUTTON
function checkSubmit(e) {
   if(e && e.charCode == 13) {
      document.getElementById('login-submit-button').click();
   }
}

function Login({ parentMethod }) {
  // Clear sessionId in parent
  useEffect(() => {
    parentMethod('');
  }, []);

  const navigate = useNavigate();

  // State params
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleUserAuth = event => {
    setLoading(true);

    getContactAffiliations(email, password, response => {
      if (response.success && response.meta.sessionId) {
        parentMethod(response.meta.sessionId);
        localStorage.setItem('sessionId', response.meta.sessionId);
        localStorage.setItem('sessionStartTime', response.meta.sessionStartTime);
        localStorage.setItem('contactData', JSON.stringify(response.data));
        localStorage.setItem('user', JSON.stringify(response.meta.user));
        localStorage.setItem('ngnmx', JSON.stringify(response.meta.ngnmx));
        localStorage.setItem('market_updates', JSON.stringify(response.meta.market_updates));
        navigate('/home')
      } else {
        // console.error(response);
        setErrorMsg(response.meta.msg);
        setErrorLoading(true);
        setLoading(false);
      }
    });
  };

  const closeToast = event => {
    setErrorLoading(false);
    setErrorMsg('');
  }

  return (
    <div id="login-page">

    {
      errorLoading &&
      <ToastContainer position='top-end' className="position-fixed toast-loader">
        <Toast bg='danger'>
          <Toast.Header closeButton={true} onClick={event => closeToast()}>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>
            {errorMsg}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    }

      <div className="flexin">
        <div id="login-container">
          <Image src={logo} fluid />
          <Form noValidate id="login-form">
              <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="enter your email" onChange={event => setEmail(event.target.value)} onKeyPress={event => checkSubmit(event)} />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="enter your password" onChange={event => setPassword(event.target.value)} onKeyPress={event => checkSubmit(event)} />
              </Form.Group>
              <div style={{marginBottom: '1rem'}}></div>
              <Button id="login-submit-button" variant="primary" type="button" onClick={event => handleUserAuth(event)}>{loading ? 'Authenticating...' : 'Submit'}</Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
