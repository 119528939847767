import { React, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import EntityModal from '../entityModal/EntityModal';
import 'bootstrap/dist/css/bootstrap.css';
import './entityCard.scss';

function EntityCard({ entity }) {
  // State
  const [clickedEntity, setClickedEntity] = useState({});
  const [showModal, setShowModal] = useState(false);

  const openEntityDetailsModal = entity => {
    setClickedEntity(entity);
    setShowModal(true);
  }

  const closeModal = (entityId) => {
    setClickedEntity('');
    setShowModal(false);
  }  

  return (
    <>
      {showModal && <EntityModal showModal={showModal} entity={clickedEntity} closeModal={entityId => closeModal(entityId)} />}

      <Card style={{width: '250px'}}>
        <Card.Img variant="top" src="https://web-buttons.com/posts/data/upload/2017/04/responsive_images.jpg" />
        <Card.Body>
          <Card.Title style={{minHeight: '48px', cursor: 'pointer'}} onClick={() => openEntityDetailsModal(entity)}>{entity.legal_entity}</Card.Title>
          <ul>
            <li>Accounts: {entity.accounts.length}</li>
            {
              entity.expiring_contracts['Electric'].length > 0 &&
              <li>{entity.expiring_contracts['Electric'].length} Electric contract(s) expiring</li>
            }
            {
              entity.expiring_contracts['Natural Gas'].length > 0 &&
              <li>{entity.expiring_contracts['Natural Gas'].length} Natural Gas contract(s) expiring</li>
            }            
          </ul>
        </Card.Body>      
      </Card>
    </>
  )
}

export default EntityCard;
