import { React, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './marketUpdateAccordion.scss';

function MarketUpdateAccordion({ amountVisible }) {
  // State
  const [marketUpdates, setMarketUpdates] = useState(() => {
    const mU = localStorage.getItem('market_updates');
    if (mU === null) return [];
    return JSON.parse(localStorage.getItem('market_updates')).reverse();
  });

  return (
    <Accordion className="market-update">
      {
        (marketUpdates.length > 0) &&
        marketUpdates.map((item, i) => (
          i < 5 &&
          <Accordion.Item key={i} eventKey={i}>
            <Accordion.Header>{item.title}</Accordion.Header>
            <Accordion.Body>
              <p className="text-muted">{item.short_description}</p>
              {item.description}
              <br /><br />
              <p className="text-muted" style={{fontSize: '0.8rem'}}>Source: <a style={{color: 'inherit', textDecoration: 'none'}} href={item.source_url} target="_blank">{item.source_url}</a></p>
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  )
}

export default MarketUpdateAccordion;
