import { React, useEffect, useState } from 'react';
import { Badge, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './summaryReport.scss';

// COMPONENTS
import CommodityIcon from '../commodityIcon/CommodityIcon';
import EntityModal from '../entityModal/EntityModal';

function SummaryReport() {
  const [entities, setEntities] = useState(JSON.parse(localStorage.getItem('contactData')).entities);
  const [clickedEntity, setClickedEntity] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [contractStatusBadges, setContractStatusBadges] = useState({
    active: 'success',
    expired: 'danger',
    future: 'info',
  });

  const openEntityDetailsModal = entity => {
    setClickedEntity(entity);
    setShowModal(true);
  }

  const closeModal = (entityId) => {
    setClickedEntity('');
    setShowModal(false);
  }

    const makeid = length => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

  const TableRow = ({entity}) => {
    let elems = [];

    const commodities = ['Electric', 'Natural Gas'];
    commodities.map(commodity => {
        if (entity.active_contracts[commodity]) {
            const contract = entity.active_contracts[commodity];

            elems.push(
              <tr key={makeid(5)}>
                <td>{contract.status && <Badge bg={contractStatusBadges[contract.status.toLowerCase()]}>{contract.status}</Badge>}</td>
                <td style={{cursor: 'pointer'}} onClick={() => openEntityDetailsModal(entity)}>{contract.legal_entity}</td>
                <td style={{verticalAlign: 'middle', textAlign: 'center'}}><CommodityIcon commodity={contract.type.toLowerCase()} /></td>
                <td style={{verticalAlign: 'middle', textAlign: 'center'}}><Image src={"https://crm.goananta.com/" + (contract.accounts.length > 0 && contract.accounts[0].utility_asset_path)} /></td>
                <td style={{verticalAlign: 'middle', textAlign: 'center'}}><Image src={"https://crm.goananta.com/" + contract.supplier_asset_path} /></td>
                <td>{contract.term}</td>
                <td>{'$' + contract.rate}</td>
                <td>{contract.date_signed}</td>
                <td>{'$' + contract.rate}</td>
                <td>{contract.start_date}</td>
                <td>{contract.end_date}</td>
              </tr>
            )
        }
    })

    return elems;
  }

  return (
    <>
      <EntityModal showModal={showModal} entity={clickedEntity} closeModal={entityId => closeModal(entityId)} />

      <Table striped bordered hover size="sm" id="leads-table" responsive id="summary-report-table">
        <thead>
          <tr>
            <th className="text-uppercase text-center">Status</th>
            <th className="text-uppercase text-center">Legal Entity</th>
            <th className="text-uppercase text-center">Commodity</th>
            <th className="text-uppercase text-center">Utility</th>
            <th className="text-uppercase text-center">Supplier</th>
            <th className="text-uppercase text-center">Term</th>
            <th className="text-uppercase text-center">Rate</th>
            <th className="text-uppercase text-center">Signage Date</th>
            <th className="text-uppercase text-center">Contract Rate</th>
            <th className="text-uppercase text-center">Start Date</th>
            <th className="text-uppercase text-center">End Date</th>
          </tr>
        </thead>
        <tbody>
          {
            entities.length > 0 &&
            entities.map((entity, i) => (
                <TableRow key={makeid(5)} entity={entity} />
            ))
          }
        </tbody>
      </Table>
    </>
  );
}

export default SummaryReport;
