import { React, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './loadFactorForm.scss';
import ContainerCol from '../containerCol/ContainerCol';
import { calcLoadFactor, capitalizeFirstLetter } from '../../assets/utils';
import styled from 'styled-components';

/* Styled Components */
const BtnClose = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const Overlay = ({ lfObj, closeOverlay }) => (
  <div id="lf-overlay">
    <button style={{position: 'absolute', top: '1rem', right: '1rem'}} type="button" className="btn-close" aria-label="Close" onClick={closeOverlay}></button>

    <h2 style={{color: lfObj.color}} className="text-center">
      <span id="load-factor-output">{lfObj.loadFactor + '%'}</span>
      <br />
      <small style={{fontStyle: 'italic'}}>{capitalizeFirstLetter(lfObj.range)}</small>
    </h2>
  </div>
)

function LoadFactorForm() {
  const [monthlyKwh, setMonthlyKwh] = useState('');
  const [billingDemandKw, setBillingDemandKw] = useState('');
  const [billingPeriod, setBillingPeriod] = useState('');
  const [validated, setValidated] = useState(false);  
  const [loadFactor, setLoadFactor] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      alert("Form not valid");
      return;
    } else {
      // We are validated and can continue
      setValidated(true);      
    }

    const lF = calcLoadFactor(monthlyKwh, billingDemandKw, billingPeriod); // monthlyKwh, billingDemandKw, billingPeriod
    setLoadFactor(lF);
  }

  const closeOverlay = () => {
    setLoadFactor('');
  }

  return !loadFactor ? (
    <div id="load-factor-form-container">      
      <Form id="load-factor-form" onSubmit={handleSubmit} validated={validated}>
        <Form.Group controlId="formBasicMonthlyKwh">
          <Form.Label>Monthly kWh *</Form.Label>
          <Form.Control required type="text" value={monthlyKwh} placeholder="Enter monthly kwh" onChange={event => setMonthlyKwh(event.target.value)} required />
        </Form.Group>
        <Form.Group controlId="formBasicBillingDemandKw">
          <Form.Label>Billing Demand (kW) *</Form.Label>
          <Form.Control required type="text" value={billingDemandKw} placeholder="Enter billing demand" onChange={event => setBillingDemandKw(event.target.value)} required />
        </Form.Group>
        <Form.Group controlId="formBasicBillingPeriod">
          <Form.Label>Billing Period (days) *</Form.Label>
          <Form.Control required type="text" value={billingPeriod} placeholder="Enter billing period" onChange={event => setBillingPeriod(event.target.value)} required />
        </Form.Group>                    

        <Button type="submit" variant="primary">Submit</Button>
      </Form>      
    </div>
  ) : <Overlay lfObj={loadFactor} closeOverlay={() => closeOverlay()} />;
}

export default LoadFactorForm;
