import { React, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getStates } from '../../assets/apiBank';

function StateSelect({ handleChange }) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    getStates(states => {
      setStates(states);
    });
  }, []);

  return (
    <div>
      <Form.Select onChange={event => handleChange(event.target.value)}>
        <option></option>
        {
          states.map((state) => (
            <option key={state.id} value={state.id}>{state.state_name}</option>
          ))
        }
      </Form.Select>  
    </div>
  );
}

export default StateSelect;
