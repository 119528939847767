import { React, useEffect, useState } from 'react';
import { Container, Col, Form, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import { getEntities } from '../assets/apiBank';
// Components
import ContainerCol from '../components/containerCol/ContainerCol';
import ContactUsForm from '../components/contactUsForm/ContactUsForm';

function ContactUs() {
  // State parameters
  const [filter, setFilter] = useState('');

  // localStorage
  // const userObj = JSON.parse(localStorage.getItem('user'));


  return (
    <div id="contact-us-page">
      <ContainerCol>
        <h1>Contact Support</h1>
        <hr />
        <ContactUsForm />
      </ContainerCol>
    </div>
  );
}

export default ContactUs;
