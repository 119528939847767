import { React } from 'react';

function AsciiChar({ type }) {
  const map = {
    default: '⊂(◉‿◉)つ',
    bearHug: 'ʕっ•ᴥ•ʔっ',
    koala: 'ʕ·͡ᴥ·ʔ',
    butt: '(‿|‿)',
    awkward: '•͡˘㇁•͡˘',
    angry: '•`_´•',
    blush: '(˵ ͡° ͜ʖ ͡°˵)',
    cute: '(｡◕‿‿◕｡)',
    derp: '☉ ‿ ⚆',
    aloha: '(✿◠‿◠)',
    gimme: '༼ つ ◕_◕ ༽つ',
    shrug: '¯\_(ツ)_/¯',
    swag: '(̿▀̿‿ ̿▀̿ ̿)',
    zombie: '[¬º-°]¬',
    tears: '(ಥ﹏ಥ)',
    strong: 'ᕙ(⇀‸↼‶)ᕗ',
    shy: '=^_^=',
  }

  return (
    <span>{type ? map[type] : map.default}</span>
  );
}

export default AsciiChar;
