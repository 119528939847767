import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './jumbotron.scss';

function Jumbotron({ children, title, backgroundUrl, extraClasses }) {
  const styles = {
    textTransform: 'uppercase', 
    fontWeight: 'bold',
  }

  if (backgroundUrl) styles.background = "url(" + backgroundUrl + ")";

  return (
    <div className={(extraClasses ? (extraClasses + ' ' + 'jumbotron') : 'jumbotron')}>
      {title && <h1 style={styles}>{ title }</h1>}
      { children }
    </div>
  );
}

export default Jumbotron;
