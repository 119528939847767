import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';

function ErrorPage() {
  return (
    <div id="error-page">
      <Container>
        <Row>
          <Col>
            <div className="wrapper">
              <div>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                  <i>404 Not Found</i>
                </p> 
              </div>
            </div>
          </Col>
        </Row>
      </Container>     
    </div>
  );
}

export default ErrorPage;
