import { React, useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { getAssetMapData } from '../../assets/apiBank';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons'
import { GOOGLE_MAPS_API_KEY } from '../../assets/constants';
import { GoogleMap, useJsApiLoader, MarkerClusterer, Marker } from '@react-google-maps/api';
import _ from 'underscore';

function GooglePlaceMap({ lat, lng, extraStyles }) {
  const [map, setMap] = useState(null);
  const [libraries, setLibraries] = useState(['places']);

  const defaultZoom = 14;

  const containerStyle = {
    width: '100%',
    height: '500px',
    ...extraStyles,
  };

  // Center is set to Columbus
  const location = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  })

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(location);
    // map.fitBounds(bounds);
    // map.setZoom(defaultZoom);

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const options = {

  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={location}
      zoom={defaultZoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={location}
      />
    </GoogleMap>
  ) : <></>;
}

export default GooglePlaceMap;
