import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

// FONT AWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons'
import { faFireFlameSimple } from '@fortawesome/free-solid-svg-icons'

function CommodityIcon({ commodity }) {
  if (typeof(commodity) == 'undefined' || (commodity !== 'electric' && commodity !== 'natural gas')) return false;

  return (
      commodity === 'electric' ? 
      <FontAwesomeIcon icon={faBoltLightning} style={{color: '#FFB300'}} />
      : <FontAwesomeIcon icon={faFireFlameSimple} style={{color: '#FF4136'}} />
  );
}

export default CommodityIcon;
