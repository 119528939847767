const host = window.location.hostname;

export const ENV = (host === 'www.anantaenergysource.com' ? 'prod' : 'dev');
export const ANNA_API_URL = (ENV === 'prod') ? 'https://crm.goananta.com/api' : "http://localhost:80/goananta_crm/api";
// export const API_URL = "http://localhost:80/evapi/";
export const API_USERNAME = "merrillm@goananta.com";
export const API_PASSWORD = "Ananta123";
export const API_AUTH_STR = btoa(API_USERNAME + ":" + API_PASSWORD);
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCZrNhjxXsjtNLtT1YiVZNGqcDW4_chs9s';

// LOGGING
console.log('hostname', window.location.hostname);
console.log('ENV', ENV);