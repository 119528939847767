import { React, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import './toastLoader.scss';

const SpinnerLoader = () => (
  <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
)

function ToastLoader({ position, text, bg, children, spinnerLoader, cbt }) {
  // STATE
  const [showA, setShowA] = useState(true);

  // FUNCTIONS
  const toggleShowA = () => setShowA(!showA);

  return (
    <ToastContainer position={position ? position : 'top-end'} className="position-fixed toast-loader">
      <Toast show={showA} onClose={toggleShowA} bg={bg ? bg : 'warning'}>
        <Toast.Header closeButton={cbt === true ? true : false}>
          <strong className="me-auto">{text ? text : 'Loading...'}{spinnerLoader == true ? <SpinnerLoader /> : <></>}</strong>
        </Toast.Header>
        <Toast.Body>
          {children ? children : 'Please wait...'}
        </Toast.Body>
      </Toast> 
    </ToastContainer>  
  );
}

export default ToastLoader;
