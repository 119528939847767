import { React } from 'react';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import _ from 'underscore';

const apiKey = 'c2eaa193cec4809cf148f0b53c5cabf4';
const cities = [
    {city: "Chicago", state: "Illinois", state_abbr: "IL", coord: {lat: "41.873587", lng: "-81.692244"}},
    {city: "Columbus", state: "Ohio", state_abbr: "OH", coord: {lat: "39.9612", lng: "-82.9988"}},
    {city: "Dallas", state: "Texas", state_abbr: "TX", coord: {lat: "32.791748", lng: "-96.833751"}},
    {city: "Manila", coord: {lat: "14.599512", lng: "120.984222"}},
    {city: "Milwaukee", state: "Wisconsin", state_abbr: "WI", coord: {lat: "43.038902", lng: "-87.906471"}},
    {city: "New York", coord: {lat: "42.3601", lng: "-71.0589"}},
];

function OpenWeatherPanel({ cityName, coordinates }) {
  let lat, lng, cityStateStr, cityObj;  

  if (!cityName && typeof coordinates !== 'undefined' && (coordinates.lat && coordinates.lng)) {    
    lat = coordinates.lat;
    lng = coordinates.lng;
    cityStateStr = coordinates.city;
  } else if (cityName && _.findWhere(cities, {city: cityName}) && typeof coordinates == 'undefined') {
    const cityObj = _.findWhere(cities, {city: cityName});
    const city = cityObj.city ? cityObj.city : '';
    const stateAbbr = cityObj.state_abbr ? cityObj.state_abbr : '';
    const cityStateStr = (city + ', ' + stateAbbr);

    lat = cityObj.coord.lat;
    lng = cityObj.coord.lng;
  }

  const { data, isLoading, errorMessage } = useOpenWeather({
    key: apiKey,
    lat: lat,
    lon: lng,
    lang: 'en',
    unit: 'imperial', // values are (metric, standard, imperial)
  });

  return (
    <ReactWeather
      isLoading={isLoading}
      errorMessage={errorMessage}
      data={data}
      lang="en"
      locationLabel={cityStateStr || ''}
      unitsLabels={{ temperature: 'F', windSpeed: 'Mp/h' }}
      showForecast
    />
  );
}

export default OpenWeatherPanel;
