import { React, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getYearsForSelect } from '../../assets/apiBank';
import _ from 'underscore';

function StartDateSelect({ handleChange }) {
  const [options, setOptions] = useState('');

  useEffect(() => {
    getYearsForSelect(opts => {
      const years = opts.years;
      const iterableOptions = [];

      _(years).forEach((months, year) => {
        _(months).forEach(monthStr => {
          iterableOptions.push(monthStr);
        })
      })

      setOptions(iterableOptions)
    });
  }, []);

  return (
    <div>
      <Form.Select onChange={event => handleChange(event.target.value)}>
        <option value="Immediate">Immediate</option>
        {
          options.length > 0 &&
          options.map(str => (
            <option key={str} value={str}>{str}</option>
          ))
        }        
      </Form.Select>  
    </div>
  );
}

export default StartDateSelect;
