import { React, useEffect, useState, setState } from 'react';
import { Form } from 'react-bootstrap';
import { getStateSuppliers } from '../../assets/apiBank';

function RecipientSelect({ stateId, handleChange }) {
  const [recipients, setRecipients] = useState('');

  useEffect(() => {
    getStateSuppliers(stateId, sups => {
      console.log('sups.recipients', sups.recipients);
      setRecipients(sups.recipients);
    });
  }, []);

  return (
    <div>
      <Form.Select className="recipientSelect" onChange={event => handleChange(event.target.value)} multiple>
        <option></option>
        {
          recipients.length > 0 &&
          recipients.map((supplier) => (
            <option key={supplier.id} value={supplier.id}>{supplier.supplier}</option>
          ))
        }
      </Form.Select>  
    </div>
  );
}

export default RecipientSelect;
