import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import ContainerCol from '../components/containerCol/ContainerCol';
import LoadFactorForm from '../components/loadFactorForm/LoadFactorForm';
import { calcLoadFactor } from '../assets/utils';

function Tools() {
  return (
    <div id="tools-page">
      <ContainerCol>
        <Row>
          <Col xs="12" md="6">
            <h3>Load Factor Calc.</h3>
            <hr />
            <div className="dashboard-panel" style={{padding: '1rem'}}>
                <LoadFactorForm />
            </div>          
          </Col>        
        </Row>
      </ContainerCol> 
    </div>
  );
}

export default Tools;
