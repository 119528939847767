import { React, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import { askOpenAI } from '../assets/apiBank';

/* Components */
import ContainerCol from '../components/containerCol/ContainerCol';
import Jumbotron from '../components/jumbotron/Jumbotron';
import ToastLoader from '../components/toastLoader/ToastLoader';

function Wizard() {
  // State parameters
  const [answer, setAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');
  // const [validated, setValidated] = useState(true);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    askOpenAI(prompt, response => {
      console.log('response:openAi', response);
      setAnswer(response.choices[0].text);
      setLoading(false);
    });
  }

  return (
    <>
      {loading === true && <ToastLoader />}

      <div id="wizard-page">
        <ContainerCol>
          <h2><i>Ask the Wizard a question...</i></h2>

          <Form onSubmit={handleSubmit}>
            <InputGroup className="mb-12">
              <Form.Control
                placeholder=""
                aria-label="Question"
                aria-describedby="question"
                onChange={event => setPrompt(event.target.value)}
              />
              <Button variant="primary" type="submit">Submit</Button>
            </InputGroup>          
          </Form>        

          <br />

          {
            answer &&
            <Jumbotron extraClasses="flex-center">
              <div>
                <h3><i>{answer}</i></h3>
              </div>
            </Jumbotron>
          }
        </ContainerCol>
      </div>
    </>
  );
}

export default Wizard;
