import { React } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';

const FullSection = ({ children }) => (
  <section className="full">
    {children}
  </section>
)

function Blog() {
  return (
    <div id="blog-page">
      <FullSection>
        <iframe src="https://www.goananta.com/blog" style={{height: '100vh', width: '100%'}} />
      </FullSection>
    </div>
  );
}

export default Blog;
