import { React } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import ProfileImage from '../assets/images/dddepth/dddepth-024.jpg';

const FullSection = ({ children }) => (
  <section className="full">
    {children}
  </section>
)

function Home() {
  const userObj = JSON.parse(localStorage.getItem('user'));

  const imgMap = [
    '005',
    '008',
    '009',
    '011',
    '013',
    '033',
    '053',
    '070',
    '084',
    '105',
    '117',
    '144',
    '146',
    '172',
    '184',
    '212',
    '236',
    '262',
    '294',
    '303',
    '331',
    '337',
    '342', 
    '353', 
    '253', 
    '236', 
    '144', 
    '111', 
    '097', 
    '086', 
    '024', 
    '028'
  ];
  const item = imgMap[Math.floor(Math.random()*imgMap.length)];
  const imgPath = require('../assets/images/dddepth/dddepth-' + item + '.jpg');

  return (
    <div id="profile-page">
      <FullSection>
        <Container fluid>
          <Row>
            <Col xs="12" md="6" id="details-column">
              <div>
                <h3>Your Agent's Profile /</h3>
                <h1>{userObj ? (userObj.first_name + ' ' + userObj.last_name) : ''}</h1>
                <hr />
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia nibh suscipit tellus hendrerit imperdiet. Proin non lectus sit amet ante vehicula maximus. Ut a risus et felis malesuada ullamcorper. Donec pretium purus in varius commodo. Suspendisse potenti. Nulla ac tortor et nunc accumsan viverra sed malesuada tortor. Aliquam tempus pulvinar risus, rutrum cursus ipsum. Etiam leo nunc, rhoncus id mi ut, sagittis sagittis neque. Morbi est libero, lacinia eget convallis in, facilisis in dui. Phasellus egestas venenatis odio vel aliquet.</p>
                <p>Duis et ipsum a nibh faucibus commodo. Vestibulum commodo, massa vel tincidunt dignissim, turpis lacus finibus nunc, id aliquet massa quam ac orci. Nullam tempus, quam id tincidunt vehicula, arcu leo volutpat massa, sed venenatis eros elit vel lectus. Phasellus at ex sit amet mauris vulputate efficitur. Vivamus imperdiet laoreet iaculis. Mauris non eros et nunc faucibus dictum. Ut ligula urna, molestie viverra odio nec, imperdiet vestibulum leo. In augue lectus, blandit in mauris tristique, hendrerit eleifend augue. Pellentesque blandit vestibulum odio quis aliquam. Morbi in odio id ex tincidunt congue.</p>
                <p>Cras fringilla risus quis risus scelerisque faucibus. Sed eu enim non mauris gravida ultrices. Nullam non dictum nisi, sit amet mollis mauris. Morbi ac neque ac elit dapibus consequat. Vestibulum imperdiet purus sed nibh pharetra, ac porta mi sollicitudin. Phasellus diam tortor, rutrum non nisi ut, convallis pellentesque odio. Vestibulum quis dui rutrum, lobortis massa at, feugiat ipsum. Proin interdum sapien ac ornare tempus. Proin id odio facilisis, pulvinar diam non, luctus sapien.</p>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="image-wrapper text-center">
                <img id="profile-image" style={{backgroundImage: `url(${imgPath})`}} />
              </div>
            </Col>            
          </Row>
        </Container>
      </FullSection>
    </div>
  );
}

export default Home;
