import { React, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './panel.scss';


function Panel({ type, heading, id, children }) {
  const panelTypes = {
    'primary': {
      'textColor': 'white',
      'bg': '#337ab7',
      'borderColor': '#337ab7',
    },
    'warning': {
      'textColor': '#8a6d3b',
      'bg': '#fcf8e3',
      'borderColor': '#faebcc',
    },
    'danger': {
      'textColor': '#a94442',
      'bg': '#f2dede',
      'borderColor': '#ebccd1',
    }, 
    'info': {
      'textColor': '#31708f',
      'bg': '#d9edf7',
      'borderColor': '#bce8f1',
    },            
  };

  return (
    <Card id={id} className="panel" style={{marginBottom: "2rem", border: ('1px solid ' + panelTypes[type].borderColor)}}>
      <Card.Title>
        <h2 style={{
          backgroundColor: panelTypes[type].bg,
          color: panelTypes[type].textColor,          
        }}>{heading}</h2>
      </Card.Title>
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  );
}

export default Panel;
