import { React, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
// import { getContacts } from '../assets/apiBank';
import ContainerCol from '../components/containerCol/ContainerCol';
import StateSelect from '../components/stateSelect/StateSelect';
import EntitySelect from '../components/entitySelect/EntitySelect';
import StartDateSelect from '../components/startDateSelect/StartDateSelect';
import StartDateTable from '../components/startDateTable/StartDateTable';
import RecipientSelect from '../components/recipientSelect/RecipientSelect';
import { getPricingRequestDefaultTemplates } from '../assets/apiBank';
import { Editor } from '@tinymce/tinymce-react';

function PricingRequest() {
  /* State Variables */
  const [validated, setValidated] = useState(false);
  const [selectedCommodity, setSelectedCommodity] = useState('Electric');
  const [pricingRequestType, setPricingRequestType] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedEntity, setSelectedEntity] = useState('');
  const [ucb, setUcb] = useState(true);
  const [startDate, setSelectedStartDate] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [term, setTerms] = useState('12, 24, 36, 48, 60');
  const [bandwidth, setBandwidth] = useState('100%');
  const [margin, setMargin] = useState('');
  const [annualUsage, setAnnualUsage] = useState('0');
  const [editorBody, setEditorBody] = useState('<p>This is the initial content of the editor.</p>');

  useEffect(() => {
    getPricingRequestDefaultTemplates(response => {
      setEditorBody(response.body);
    });
  })

  /**
   * HANDLERS
   */
  const handleCommoditySelect = commodity => {
    // See if there is an account object that matches the commodity selected
    if (commodity) {
      setSelectedCommodity(commodity);
    }
  }
  const handlePricingRequestTypeSelect = type => {
    // See if there is an account object that matches the commodity selected
    if (type) {
      setPricingRequestType(type);
    }
  }
  const handleStateSelect = stateId => {
    console.log('stateId', stateId);
    // See if there is an account object that matches the commodity selected
    if (stateId) {
      setSelectedState(stateId);
    }
  }
  const handleEntitySelect = entityId => {
    console.log('entityId', entityId);
    // See if there is an account object that matches the commodity selected
    if (entityId) {
      setSelectedEntity(entityId);
    }
  }
  const handleUCBChange = checked => {
    setUcb(!checked);
    console.log('ucb', ucb);   
  }
  const handleStartDateSelect = startDate => {
    console.log('startDate', startDate);
    // See if there is an account object that matches the commodity selected
    if (startDate) {
      setSelectedStartDate(startDate);
    }
  }
  /**
   * END ---> HANDLERS
   */





  const handleSubmit = () => {

  }

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <div id="pricing-request-page">
      <ContainerCol>
        <h1>Pricing Requests</h1>
        <hr />

        <Row>
          <Col xs="12" md="6">
            <Form onSubmit={handleSubmit} validated={validated}>
               <Form.Group className="mb-12" controlId="formBasicCommodity">
                <label>Is this a new or old pricing request?</label>
                <Form.Select id="document__pricing-request-type-select" onChange={event => handlePricingRequestTypeSelect(event.target.value)}>
                    <option></option>
                    <option value="New">New</option>
                    <option value="Old">Old</option>
                </Form.Select>
              </Form.Group> 
               <Form.Group className="mb-12" controlId="formBasicState">
                <label>Which state is this for?</label>
                <StateSelect handleChange={(stateId) => handleStateSelect(stateId)} />
              </Form.Group>  
               <Form.Group className="mb-12" controlId="formBasicEntity">
                <label>Which entity will we be working with?</label>
                <EntitySelect handleChange={(entityId) => handleEntitySelect(entityId)} />
              </Form.Group>  
               <Form.Group className="mb-12" controlId="formBasicCommodity">
                <label>Select a commodity</label>
                <Form.Select id="document__commodity-select" defaultValue={selectedCommodity} onChange={event => handleCommoditySelect(event.target.value)}>
                    <option></option>
                    <option value="Electric">Electric</option>
                    <option value="Natural Gas">Natural Gas</option>
                </Form.Select>
              </Form.Group>  
              <Form.Group>
                <label>Select the accounts that are involved with this entity:</label>
              </Form.Group>
              <Table striped bordered hover id="account-select-table" size="sm" variant="dark" responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>Legal Entity</th>
                    <th>DBA</th>
                    <th>Utility</th>
                    <th>Acct #</th>
                    <th>Meter #</th>
                    <th>Annual Usage</th>
                    <th>Proxy</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>
              </Table>

              <hr />

             <Form.Group className="mb-12" controlId="formBasicCommodity">
              {/*<label>UCB</label>*/}
              <Form.Check 
                type={'checkbox'}
                label={`UCB`}
                defaultChecked={true}
                name="ucb"
                onClick={event => handleUCBChange(event.target.checked)}
              /> 
            </Form.Group>

            <hr />

             <Form.Group className="mb-12" controlId="formBasicStartDate">
              <label>Start Date</label>
              <StartDateSelect handleChange={startDate => handleStartDateSelect(startDate)} />
            </Form.Group>  

            {/*<StartDateTable accounts={selectedAccounts} />*/}

            <Form.Group controlId="terms">
              <Form.Label>Terms (Months)</Form.Label>
              <Form.Control type="text" placeholder="enter terms" defaultValue={term} onChange={event => setTerms(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="bandwidth">
              <Form.Label>Bandwidth</Form.Label>
              <Form.Control type="text" placeholder="enter bandwidth" defaultValue={bandwidth} onChange={event => setBandwidth(event.target.value)} />
            </Form.Group>  

            <Form.Group controlId="margin">
              <Form.Label>Margin {selectedCommodity == 'Electric' ? '($/MWh)' : ''}:</Form.Label>
              <Form.Control type="text" placeholder="enter margin" defaultValue={selectedCommodity == 'Electric' ? '1' : '$0.10'} onChange={event => setMargin(event.target.value)} />
            </Form.Group>  

            <Form.Group controlId="annualUsage">
              <Form.Label>Approximate Annual Usage (From Bill):</Form.Label>
              <Form.Control type="text" placeholder="enter usage" defaultValue={annualUsage} onChange={event => setAnnualUsage(event.target.value)} />
            </Form.Group>

            <hr />

            <h3>Recipients</h3>

            {selectedState && <RecipientSelect stateId={selectedState} />}

            </Form>
          </Col>

          <Col xs="12" md="6">
            {
              Editor ?
              <Editor
                apiKey='5idnynker49hflu9gyg0byaanjictlh69jnfxflr537epxp4'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={editorBody}
                onChange={event => setEditorBody(event.target.value)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              /> : <p>Loading...</p>              
            }

            <Button onClick={log} style={{marginTop: '1rem'}} variant="primary">Log</Button>

          </Col>          
        </Row>
      </ContainerCol>
    </div>
  );
}

export default PricingRequest;
