import { createContext, useContext, useState, React } from 'react';
import './App.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { ENV } from './assets/constants';
import DefaultNavbar from './components/defaultNavbar/DefaultNavbar';
import { HashRouter as Router, RouterProvider, Route, Routes, useRoutes, matchRoutes, useLocation, useNavigate } from "react-router-dom";
// Components
import Blog from './routes/Blog';
import Contacts from './routes/Contacts';
import ContactUs from './routes/ContactUs';
import Entities from './routes/Entities';
import Home from './routes/Home';
import Login from './routes/Login';
import PricingRequest from './routes/PricingRequest';
import Profile from './routes/Profile';
import ToastLoader from './components/toastLoader/ToastLoader';
import Tools from './routes/Tools';
import Wizard from './routes/Wizard';
import ErrorPage from './routes/ErrorPage';
import moment from 'moment';

function App() {
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId'));
  const [showLoader, setShowLoader] = useState(false);

  // For dev purposes only - Changed tab icon for dev environment to distinguish from prod
  if (ENV == 'dev') document.querySelector('[rel="icon"]').setAttribute('href', 'data:image/x-icon;base64,AAABAAEAEBAAAAEACABoBQAAFgAAACgAAAAQAAAAIAAAAAEACAAAAAAAAAEAAAAAAAAAAAAAAAEAAAAAAACoSVcAqMhYADnpxQB3t4kA6pgZAKYZWAA2+MUAqFhXADe5xgDX9ycAKcjYABYn5wDoNxgABvb2AMjYNwC36UYAJSjYAHinhwDH5zcAtvhGAGk3mACniVoA1icoANamKQD5CAoAGEjnAFeXqQCVJ2gA+BcKAIc4dwD5WAYA52cYABcY6AAH5/cAtxlHAAS3+AAnGdkAiId5ALUoRwBn6JYAVzaoANlIKABoKJkApvdWAKZ5WAA2mMgASIe5APf4BwAm6NYA+TcHANeXKgAICPgAabiXAEcXuAA2KMcAmNhmACa41wCYGGkA6FcWANhmKACml1gAl+dmALpJRwAX2OYAaFeWAFcnqQCUt2cAyQg5ALjZRQBp2ZcASLe5APinCQD5KAgAOYnJAMYXOQB3mIkA2IcoAAc4+ABoppgAeliHAJUIZwCoOVcAFfbmADnZxQAWeOgA6ccYAAhH+AC4uEYAJffXABYI5wAHF/kAeTeIAKfYVQA3eccAh3d4AChI1wB4iIcA+OgGAOi4GQDq9xgA2sYoAAh3+ADHmDgANxjGAHcoiQDGpzgA6kgYAGr3mACEKHcASNe3ACZ41wCIZ3YAB1j2AHf4iQC3CUcAg7Z4AEfmtwBpCZkAa0iYAOgnGQAGZ/YARTe3AGqXmgA3CccAmHdnADlIxgCGyHgAiAd4AFe3qQBY9qgAlkdoACdo1QDGKDgAWMapAJWYZwBaR6gAh+h1APtIBwB4eIYA+ogJABiH6gDINzgACNf4AEhntwBrh5gANznHAPqXCQAGp/kAeceIAEh2twAoWdYA6XcWAEkHuQCXyGcA+XgHACeo2ACHGXkAiVh4AMdnOADqhxkApQhYALg5SADX5icA+scJABjI5wDGuDcA6dgYAFhYpgCVZ2YAlKdoALeKRwC5yUYAh/d2ACcI2AAXmOgAZxeYAFhnpgDIiDgAycc3AFenqAB6CYkApulVAKWoWQBZ5qcAfEiIABWn6ACISHYAOcnGALdoRQBYdqYApbdZALioRwBJx7cAFjfnAIeXeAD4uAoAeOeGAOoIGQBod5cACYj4ADdoxQDoFxkAKDjYAAaX+ADG9zcAyHg2AOenFwAFJ/cA6eYWANc3KAAXWOcA2LYpAKVnVQClKFYAtplIALhZRgAVZ+cAFejlAMdXNwB4GIoAaGiYADhZxgDZ1ykACMf4ALZ6RgBHV7cAWgepAIWmeQCXWGgAN6jIAESmuQBYFqkARie4AGnIlwBY16kA+dgIACeJ2ADYdigAyUg4AJX3ZgBH97gAljdpAPhoBwAn19cAJ5jYANgHKgB52IgAR0i4ABS45wCXiGgA11cmAIjYdgDXFioAeGiHAEaXugBZiKgAM1mte5jidbR/UKByQ/XFGFogJGc156/bnDkFIkr8yRzPCxA26EEqaGwb1SaEFndITcHKkXkoFFsd8VGhjdEMMVYZX333h3a4uoIAPu4paolw0pbd4adAT53kB9fa+joeeNiDyI+w3P1vqNS8njsf8mVUbl2VvcaKXnws4M3tl5rHjOxJLv+QYCX5FaqxTJ+Ly670Lf4aekvChjzWZjIEkpO5m+Xms04R46m2v2kXzkcj+DgIRoA0A3NCvlel02LD36QKu8CF6ZR+mQGrsmRVo44/81Nt6kX2+zdcRA7epush2TACdLcnxIg9tQ8SotBhDVJYBvCBa3Gs7ysTzAljLwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=');

  const CRMLayout = ({ children }) => {
    const navigate = useNavigate();

    const userLogout = () => {
      localStorage.clear();
      setSessionId('');
      navigate('/');
    };

    // This will log you out after a certain period of time...let's say 30 minutes for right now
    setTimeout(() => {
      setShowLoader(true);
      userLogout();

      // Cancel the toast loader after 30 seconds
      setTimeout(() => {
        setShowLoader(false);
      }, 30000);

    }, 1200000)

    return (
      <>
        <DefaultNavbar fixed="top" userLogout={userLogout} />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/entities" element={<Entities />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/pricing-request" element={<PricingRequest />}></Route>
          <Route path="/tools" element={<Tools />}></Route>
          <Route path="/user-profile" element={<Profile />}></Route>
          <Route path="/wizard" element={<Wizard />}></Route>
          {/*<Route path="/contacts" element={<Contacts />}></Route>*/}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </>
    )
  }

  const PublicRoutes = () => (
    <>
      {showLoader && <ToastLoader bg="danger" text="Logged Out" spinner-loader={false} cbt={true}>User logged out after 30 mins</ToastLoader>}
      <Routes>
        {/* OUTLIERS */}
        <Route index path="/" element={<Login parentMethod={sessionId => setSessionId(sessionId)} />}></Route>
        <Route path="/login" element={<Login parentMethod={sessionId => setSessionId(sessionId)} />}></Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )

  return (
    <Router>
      {
        sessionId ? <CRMLayout /> : <PublicRoutes />
      }
    </Router>
  );
}

export default App;
