export const makeId = function(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const cloneArray = function(arr) {
    const newArray = arr.map(a => ({...a}));

    return newArray;
}

export const findIndex = function(arr, prop, value) {
    const index = arr.findIndex(x => x[prop] === value);

    return index;
}

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
};

export const numberWithCommas = (x) => {
    if (!x) return 0;

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const dynamicSort = property => {
    let sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

// export const dynamicSort = property => {
//     let sortOrder = 1;

//     if(property[0] === "-") {
//         sortOrder = -1;
//         property = property.substr(1);
//     }

//     return function (a,b) {
//         /* next line works with strings and numbers, 
//          * and you may want to customize it to your needs
//          */
//         const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//         return result * sortOrder;
//     }
// }

export const calcLoadFactor = (monthlyKwh, billingDemandKw, billingPeriod) => {
    const loadFactor = (parseFloat(monthlyKwh / (billingDemandKw * billingPeriod * 24)) * 100).toFixed(2);
    let range = '';
    let color = 'inherit';

    if (loadFactor >= 0 && loadFactor <= 39) {
        range = 'low';
        color = '#FF4136';
    } else if (loadFactor >= 40 && loadFactor <= 60) {
        range = 'medium';
        color = '#FFDC00';
    } else if (loadFactor >= 61) {
        range = 'high';
        color = '#2ECC40';
    }

    return {
        loadFactor: loadFactor,
        range: range,
        color: color,
    }
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const logFormData = formData => {
    // Display the key/value pairs
    for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
}

export const scrollTo = htmlIdentifier => {
    // var aTag = $("#" + id);
    // $('html,body').animate({scrollTop: aTag.offset().top},'slow');

    const element = document.getElementById(htmlIdentifier);
    element.scrollIntoView();
}