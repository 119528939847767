import { React, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import ContainerCol from '../components/containerCol/ContainerCol';
import EntitiesTable from '../components/entitiesTable/EntitiesTable';
import { getEntities } from '../assets/apiBank';

function Entities() {
  // State parameters
  const [filter, setFilter] = useState('');

  // localStorage
  // const userObj = JSON.parse(localStorage.getItem('user'));


  return (
    <div id="entities-page">
      <ContainerCol>
        <h1>Entities</h1>
        <hr />

        <Form.Control type="text" placeholder="Search..." style={{marginBottom: '1rem'}} onChange={event => setFilter(event.target.value)} />

        <EntitiesTable filter={filter} />
      </ContainerCol>
    </div>
  );
}

export default Entities;
