import { React, useEffect, useState, setState } from 'react';
import { Form } from 'react-bootstrap';
import { getEntities } from '../../assets/apiBank';

function EntitySelect({ handleChange }) {
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    getEntities(etts => {
      setEntities(etts);
    });
  }, []);

  return (
    <div>
      <Form.Select className="entitySelect" onChange={event => handleChange(event.target.value)}>
        <option></option>
        {
          entities.length > 0 &&
          entities.map((entity) => (
            <option key={entity.id} value={entity.id}>{entity.legal_entity}</option>
          ))
        }
      </Form.Select>  
    </div>
  );
}

export default EntitySelect;
