import { React, useEffect, useState, setState } from 'react';
import { Form } from 'react-bootstrap';
import { Bar, BarChart, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import moment from 'moment';

function NgnmxChart({ line }) {
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    const q = JSON.parse(localStorage.getItem('ngnmx')).quotes;
    const quotes = [];

    q.map((o, i) => {
      quotes.push({
        name: moment(o.quote_timestamp).format('M-D-YYYY'),
        price: o.quote,
      });
    });

    setQuotes(quotes);
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {    
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor: '#F2F2F2', padding: '0.3rem', borderRadius: '5px', border: '1px solid #ddd'}}>
          <p className="label">{label}</p>
          <p className="label"><u>Price (EOD)</u>: {'$' + payload[0].value}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {
        quotes.length > 0 &&
        <ResponsiveContainer width="100%" height="80%">

            {
              line === true ?
              <LineChart width={730} height={250} data={quotes} margin={{ top: 10, right: 30, left: 0, bottom: -30 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <XAxis dataKey="name"></XAxis>
                <YAxis dataKey="price" />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <Line dot={false} type="monotone" dataKey="price" fill="#8884d8" />
              </LineChart>              
              :
              <BarChart width={730} height={250} data={quotes} margin={{ top: 10, right: 30, left: 0, bottom: -30 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <XAxis dataKey="name"></XAxis>
                <YAxis dataKey="price" />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <Bar dot={false} type="monotone" dataKey="price" fill="#8884d8" />
              </BarChart>              
            }
        </ResponsiveContainer>
      }
    </>
  );
}

export default NgnmxChart;
