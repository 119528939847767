import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import './styles/home.scss';
import anantaLogo from '../ananta_logo.png';
import { Card, Col, Container, Image, Row, Table, Fade } from 'react-bootstrap';
import { ENV } from '../assets/constants';
import { scrollTo } from '../assets/utils';
import axios from 'axios';

/* COMPONENTS */
import EntityCard from '../components/entityCard/EntityCard';
import ExpiringContractsTable from '../components/expiringContractsTable/ExpiringContractsTable';
import MarketUpdateAccordion from '../components/marketUpdateAccordion/MarketUpdateAccordion';
import NgnmxChart from '../components/ngnmxChart/NgnmxChart';
import Panel from '../components/panel/Panel';
import SummaryReport from '../components/summaryReport/SummaryReport';
import OpenWeatherPanel from '../components/openWeatherPanel/OpenWeatherPanel';
import TradingViewWidget from '../components/tradingViewWidget/TradingViewWidget';

function Home() {
  // STATE PARAMS
  const [entities, setEntities] = useState([]);
  const [expiringContracts, setExpiringContracts] = useState([]);
  const [weatherCoord, setWeatherCoord] = useState({lat: '', lng: ''});
  const [modalController, setModalController] = useState({
    showModal: false,
    entity: {},
  });
  const [userObj, setUserObj] = useState({});
  const [contactObj, setContactObj] = useState({});
  const [ngnmxQuotes, setNgnmxQuotes] = useState([]);
  let [activeAccountsCount, setActiveAccountsCount] = useState(0);
  const [fadeImage, setFadeImage] = useState(false);

  useEffect(() => {
    const contactData = JSON.parse(localStorage.getItem('contactData'));
    setNgnmxQuotes(JSON.parse(localStorage.getItem('ngnmx')).quotes);
    setActiveAccountsCount(0);
    setEntities(contactData.entities);
    setExpiringContracts(contactData.expiring_contracts);
    setUserObj(contactData.contact);

    if (contactData.entities.length > 0) {
      contactData.entities.map((entity, i) => {
        if (entity.accounts.length > 0) {
          entity.accounts.map((account, ai) => {
            activeAccountsCount++;
            if (parseInt(account.active) === 1) setActiveAccountsCount(activeAccountsCount);
          })
        }
      })
    }

    // GET WEATHER DATA BASED ON IP ADDRESS
    if (ENV !== 'dev') {
      axios.get('https://ipinfo.io?token=00dc27cedce4c6')
        .then(response => {
          console.log('ip info:', response);
          if (response.data) {
            const loc = response.data.loc.split(',');

            setWeatherCoord({lat: loc[0], lng: loc[1], city: response.data.city});
          }
        })
        .catch(error => {
          console.log("ERROR");
        })
    }

    // HOME PAGE IMAGE - FADE IN
    setTimeout(() => {
      setFadeImage(true);
    }, 300);

  }, []);

  const mapIconClick = entityObj => {
    setModalController({
      showModal: true,
      entity: entityObj,
    })
  }

  return (
    <div id="home-page">
      <div className="App-header">
        <Fade in={fadeImage}><a href="https://www.goananta.com" target="_blank"><Image fluid style={{cursor: 'pointer'}} src={anantaLogo} id="ananta-logo" alt="logo" /></a></Fade>
      </div>

      <section>
        <Container fluid>
          <Row>
            {/* LEFT SIDEBAR */}
            <Col md="4">
                <Card style={{backgroundColor: 'white', borderRadius: '4px'}}>
                  <Card.Body>
                    <h2>{userObj.first_name + ' ' + userObj.last_name}<br /><small className="text-muted" style={{fontSize: '1rem', margin: '0'}}>{userObj.email}</small></h2>
                    <hr />
                    <ul>
                      {expiringContracts.length > 0 && <li style={{color: '#FF4136'}}>You have <u style={{cursor: 'pointer'}} onClick={() => scrollTo('expiring-contracts-panel')}>{expiringContracts.length}</u> expiring contracts.</li>}
                      <li>You have <u>{activeAccountsCount}</u> active accounts between <u>{entities.length}</u> entities</li>
                    </ul>
                  </Card.Body>
                </Card>

                <br />

                <Card style={{backgroundColor: 'white', borderRadius: '4px'}}>
                  <Card.Body>
                    <h3>NG Futures Trend <sup className="text-muted">(NGNMX1!)</sup></h3>
                    <p>Last {ngnmxQuotes.length} days</p>
                    <hr />
                    <div style={{height: '250px'}}><NgnmxChart line={true} /></div>
                  </Card.Body>
                </Card>

                <br />

                <Card style={{backgroundColor: 'white', borderRadius: '4px'}}>
                  <Card.Body>
                    <h3>Market Updates</h3>
                    <hr />
                    <MarketUpdateAccordion amountVisible="5" />
                  </Card.Body>
                </Card>

                <br />

                {
                  weatherCoord.lat &&
                  <Card style={{backgroundColor: 'white', borderRadius: '4px'}}>
                    <Card.Body>
                      <h3>Current Weather</h3>
                      <hr />
                      <OpenWeatherPanel coordinates={weatherCoord} />
                    </Card.Body>
                  </Card>
                }
            </Col>

            {/* MAIN CONTENT - RIGHT */}
            <Col md="8">
{/*              <Panel heading="NYMEX-NG1!" type="primary">
                <p><small className="text-muted">2 day delay</small></p>
                <TradingViewWidget />
              </Panel>*/}

              <Panel heading="Contract Summary Report" type="primary">
                <SummaryReport />
              </Panel>
              {expiringContracts.length > 0 &&
                <Panel id="expiring-contracts-panel" heading="Expiring Contracts" type="primary">
                  <ExpiringContractsTable />
                </Panel>
              }
            </Col>
          </Row>

        </Container>
      </section>

    </div>
  );
}

export default Home;
