import { React, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { getEntities, getEntityById, updateEntity } from '../../assets/apiBank';

// COMPONENTS
import CommodityIcon from '..//commodityIcon/CommodityIcon';
import DocumentDownloadModal from '../documentDownloadModal/DocumentDownloadModal';
import EntityModal from '../entityModal/EntityModal';
import SpinnerLoader from '../spinnerLoader/SpinnerLoader';
import ToastLoader from '..//toastLoader/ToastLoader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faFile } from '@fortawesome/free-solid-svg-icons';
import _ from 'underscore';

const Loader = () => (
  <h3>Loading... <SpinnerLoader /></h3>
)

function EntitiesTable({ filter }) {
  // State
  const [entities, setEntities] = useState([]);
  const [clickedEntity, setClickedEntity] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showLeadNoteModal, setShowLeadNoteModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  // localStorage
  const userObj = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const contactData = JSON.parse(localStorage.getItem('contactData'));
    setEntities(contactData.entities);
  }, []);

  const openEntityDetailsModal = entity => {
    setClickedEntity(entity);
    setShowModal(true);
  }
  const openDocumentDownloadModal = entity => {
    setClickedEntity(entity);
    setShowDocumentModal(true);
  }

  const closeModal = (entityId) => {
    setClickedEntity('');
    setShowModal(false);
  }
  const closeDocumentModal = (entityId) => {
    setClickedEntity('');
    setShowDocumentModal(false);
  }

  const refreshEntity = (entity, callback) => {
    getEntityById(entity.id, entityObj => {
      if (typeof callback == 'function') callback(entityObj);
    })    
  }

  return entities.length > 0 ? (
    <>
      {showModal && <EntityModal showModal={showModal} entity={clickedEntity} closeModal={entityId => closeModal(entityId)} />}

      {showSpinner && <ToastLoader />}

      <Table striped bordered hover size="sm" variant="dark" id="entities-table" responsive>
        <thead>
          <tr>
            <th>Legal Entity</th>
          </tr>
        </thead>
        <tbody>
          {
            entities.filter(row => {
              return !filter ? row : row.legal_entity.toString().toLowerCase().includes(filter.toString().toLowerCase())
            })          
            .map(entity => (
              <tr key={entity.client_id}>
                <td style={{cursor: 'pointer'}} onClick={() => openEntityDetailsModal(entity)}>{entity.legal_entity} <span style={{marginLeft: '1rem'}}><CommodityIcon commodity="electric" /> {entity.accounts_by_commodity['Electric'].length}</span><span style={{marginLeft: '0.5rem'}}><CommodityIcon commodity="natural gas" /> {entity.accounts_by_commodity['Natural Gas'].length}</span></td>   
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  ) : <Loader />;
}

export default EntitiesTable;
