import axios from 'axios';
import qs from 'querystring';
import { ANNA_API_URL, API_URL, API_AUTH_STR } from './constants';
import moment from 'moment';
import { logFormData } from './utils';

export const authenticate = function(username, password, callback) {
	const authStr = btoa(username + ":" + password);

	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: authStr,
	  })

	})
	  .then(res => {
	    if (res.data.success) {
	    	if (typeof callback == 'function') callback(res.data);
	    } else {

	    }
	  });
}

export const getContactAffiliations = function(username, password, callback) {
	const authStr = btoa(username + ":" + password);

	axios({
	    url: ANNA_API_URL,
	    method: 'post',
	    headers: {
	        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	    },
	    data: qs.stringify({
		      authentication: authStr,
		      sessionId: localStorage.getItem('sessionId'),
	        method: 'getContactAffiliations',
	    })
	})
	    .then((response) => {
	        var data = response.data;
	        if (typeof callback == 'function') callback(data);
	    });
}

export const getNRGMarketUpdate = function() {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getNRGMarketUpdate",
	  })

	})
	  .then(res => {
	    console.log('res', res);
	  });
}

export const getEntities = callback => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getEntities",
	      params: JSON.stringify({
	      	user_id: JSON.parse(localStorage.getItem('user')).id
	      }),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getEntityById = (entityId, callback) => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getEntityById",
	      params: JSON.stringify({client_id: entityId}),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {
	    	alert("Error");
	    }
	  });
}

export const getAssetMapData = function(userId, callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getAssetMapData",
	      params: JSON.stringify({user_id: userId}),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getSalesStages = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getSalesStages",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getContacts = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getContacts",
	      params: JSON.stringify({userId: JSON.parse(localStorage.getItem('user')).id}),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getTopShots = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getTopShots",
	      params: JSON.stringify({userId: JSON.parse(localStorage.getItem('user')).id}),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getUtilities = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getUtilities",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getStates = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getStates",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getChargeTypes = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getChargeTypes",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getIndustries = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getIndustries",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getOrganizations = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getOrganizations",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getDSIREPrograms = function(from, to, callback) {
	if (!from || !to) return;

	const fromStr = moment(from).format('YYYYMMDD');
	const toStr = moment(to).format('YYYYMMDD');

	axios({
	  url: "https://programs.dsireusa.org/api/v1/getprogramsbydate/" + fromStr + "/" + toStr + "/json",
	  method: "GET",
	})
	  .then(res => {
	  	console.log('res', res);
	    // if (res.data.data) {
	    // 	if (typeof callback == 'function') callback(res.data.data);
	    // } else {

	    // }
	  });
}

export const saveLead = function(formData, callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "saveLead",
	      params: JSON.stringify({
	      	"user_id": JSON.parse(localStorage.getItem('user')).id,
	      	"params": formData,
	      })
	  })
	})
	  .then(res => {
	    if (res.data.data) {
	    	if (res.data.error) {
	    		alert("Error: " + res.data.meta.msg);
	    		console.error(res.data.data);
	    	}

	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {
	    	// alert("Error");
	    	console.error(res.data);
	    }
	  });
}

export const updateLead = function(leadId, updateData, callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "updateLead",
	      params: JSON.stringify({
	      	lead_id: leadId,
	      	data: updateData,
	      }),
	  })
	})
	  .then(res => {
	    if (res.data.data) {
	    	if (res.data.error) {
	    		alert("Error: " + res.data.meta.msg);
	    		console.error(res.data.data);
	    	}

	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {
	    	// alert("Error");
	    	console.error(res.data);
	    }
	  });
}

export const updateEntity = function(entityId, updateData, callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "updateEntity",
	      params: JSON.stringify({
	      	client_id: entityId,
	      	data: updateData,
	      }),
	  })
	})
	  .then(res => {
	    if (res.data.data) {
	    	if (res.data.error) {
	    		alert("Error: " + res.data.meta.msg);
	    		console.error(res.data.data);
	    	}

	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {
	    	alert("Error");
	    	console.error(res.data);
	    }
	  });
}

export const getNewLeads = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getNewLeads",
	      params: JSON.stringify({
	      	user_id: JSON.parse(localStorage.getItem('user')).id,
	      }),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getLeadById = function(leadId, callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getLeadById",
	      params: JSON.stringify({lead_id: leadId}),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const downloadDocument = (formData, callback) => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
		authentication: API_AUTH_STR,
		sessionId: localStorage.getItem('sessionId'),
		method: "downloadDocument",
		params: JSON.stringify(formData),
	  })
	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data, res.data.meta);
	    } else {

	    }
	  });
}

export const downloadAnvilPDF = (formData, callback) => {
    const ANVIL_API_KEY = 'QsznUUgFbeVeadHGEjr8NTWHAL0rX8WT';
    const encodedToken = btoa(ANVIL_API_KEY);

    const payload = {
      "title": "HUDSON General LOA",
      "fontSize": 10,
      "textColor": "#333333",
      "data": {
        "service-address": "Service Address",
        "account-number": "Account Number",
        "service-address-2": "Service Address 2",
        "account-number-2": "Account Number 2",
        "service-address-3": "Service Address 3",
        "account-number-3": "Account Number 3",
        "legal-entity": "Legal Entity",
        "contact-name": "Contact Name",
        "contact-title": "Contact Title"
      }
    };

    axios({
      url: "https://app.useanvil.com/api/v1/fill/GEFubnmCmFDEIq4AHCOE.pdf",
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/pdf',
          "Authorization": ("Basic " + encodedToken),
      },
      data: payload
    })
      .then(res => {
        if (typeof callback == 'function') callback(res.data);
      });
}

export const toggleTopShot = (userId, contactId, topShotFlag, callback) => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
		authentication: API_AUTH_STR,
		sessionId: localStorage.getItem('sessionId'),
		method: "toggleTopShot",
		params: JSON.stringify({
		    "user_id": userId,
		    "contact_id": contactId,
		    "top_shot": topShotFlag, // Send the opposite of what it currently is to simulate a toggle
		}),
	  })
	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getBlogsFromSite = callback => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getBlogsFromSite",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getYearsForSelect = callback => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getYearsForSelect",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getSuppliers = function(callback) {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getSuppliersPub",
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const getStateSuppliers = (stateId, callback) => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getStateSuppliers",
				params: JSON.stringify({
				    "state_id": stateId,
				}),
	  })

	})
	  .then(res => {
	    if (res.data.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const askOpenAI = (prompt, callback) => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "askOpenAI",
				params: JSON.stringify({
				    "prompt": prompt,
				}),
	  })

	})
	  .then(res => {
	    if (res.data) {
	    	if (typeof callback == 'function') callback(JSON.parse(res.data.data));
	    } else {

	    }
	  });
}

export const getPricingRequestDefaultTemplates = callback => {
	axios({
	  url: ANNA_API_URL,
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	  },
	  data: qs.stringify({
	      authentication: API_AUTH_STR,
	      sessionId: localStorage.getItem('sessionId'),
	      method: "getPricingRequestDefaultTemplates",
				params: JSON.stringify({
					"user_id": JSON.parse(localStorage.getItem('user')).id,
				}),
	  })

	})
	  .then(res => {
	    if (res.data) {
	    	if (typeof callback == 'function') callback(res.data.data);
	    } else {

	    }
	  });
}

export const contactSupport = (postData, callback) => {
    axios({
      url: ANNA_API_URL,
      method: "POST",
      headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      data: qs.stringify({
          authentication: API_AUTH_STR,
          sessionId: localStorage.getItem('sessionId'),
          method: "contactSupport",
          params: JSON.stringify({
              "user_id": postData.userId,
              "email": postData.email,
              "subject": postData.subject,
              "message": postData.message,
              "client_id": postData.entityId,
              "account_id": postData.accountId,
          }),
      })

    })
      .then(res => {
        if (res.data) {
            if (typeof callback == 'function') callback(res.data);
        } else {
            console.error('ERROR in contactSupport');
        }
      });
}
