import { React, useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import { getContacts } from '../assets/apiBank';
import ContainerCol from '../components/containerCol/ContainerCol';
import ContactsTable from '../components/contactsTable/ContactsTable';

function Contacts() {
  const [filter, setFilter] = useState('');

  return (
    <div id="contacts-page">
      <ContainerCol>
        <h1>Contacts</h1>
        <hr />

        <Form.Control type="text" placeholder="search..." style={{marginBottom: '1rem'}} onChange={event => setFilter(event.target.value)} />

        <ContactsTable filter={filter} />
      </ContainerCol> 
    </div>
  );
}

export default Contacts;
