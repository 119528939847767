import { React, useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, Label, Line, Tooltip, XAxis, YAxis, linearGradient, defs, ResponsiveContainer } from 'recharts';
import _ from 'underscore';
import moment from 'moment';

function PricingHistoryChart({ entity, type }) {
  const [data, setData] = useState([]);

  useEffect(() => {   
    let eData = []; 
    const contactData = JSON.parse(localStorage.getItem('contactData'));
    const chartData = entity.contracts_by_type[type];
    
    chartData.map(contract => {
      eData.push({
        "name": moment(contract.date_signed).format('M-D-YYYY'),
        "rate": contract.rate,
        "term": contract.term,
        "supplier": contract.supplier,
      });
    })

    setData(eData);
  }, []);

  function formatYAxis(value) {
    return '$' + Number(value).toFixed(3)
  }

  const CustomTooltip = ({ active, payload, label }) => {    
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor: '#F2F2F2', padding: '0.3rem', borderRadius: '5px', border: '1px solid #ddd'}}>
          <p className="label"><u>Date Signed</u>: {label}</p>
          <p className="label"><u>Rate</u>: {'$' + payload[0].value}</p>
          <p className="label"><u>Term</u>: {payload[0].payload.term}</p>
          <p className="label"><u>Supplier</u>: {payload[0].payload.supplier}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="80%">
      <AreaChart width={600} height={400} data={data}>
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
        </defs>    
        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
        <XAxis dataKey="name"></XAxis>
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip content={<CustomTooltip />} />
        <Area type="monotone" dot={true} dataKey="rate" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default PricingHistoryChart;
