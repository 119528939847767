import { React, useEffect, useState } from 'react';
import { Accordion, Badge, Button, Col, Container, Modal, Row, Table, ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './entityModal.scss';
// import { getEntityById } from '../../assets/apiBank';
import { numberWithCommas } from '../../assets/utils';
import moment from 'moment';
import styled from "styled-components";

// COMPONENTS
import GooglePlaceMap from '../googlePlaceMap/GooglePlaceMap';
import PricingHistoryChart from '../pricingHistoryChart/PricingHistoryChart';
import ContactUsForm from '../contactUsForm/ContactUsForm';

// STYLED COMPONENTS
const SpaceAfter = styled.div`
    margin-after: 1rem;
`;

function EntityModal({ showModal, closeModal, entity }) {
  const [show, setShow] = useState(false);
  const [showContactUsForm, setShowContactUsForm] = useState(false);
  const [toggleBody, setToggleBody] = useState(true);

  const Loader = () => (
    <h3>Loading...</h3>
  )

  const handleShow = () => setShow(true);

  const showTestBody = () => {
    setToggleBody(!toggleBody);
    document.getElementById("entity-modal").scrollIntoView(true);
  }

  const MinTrackingChart = ({iframe}) => {
    return (
        <Row style={{overflowX: 'scroll'}}>
            <Col xs="12">
                <div className="tracking-graph" dangerouslySetInnerHTML={{__html: iframe}} />
            </Col>
        </Row>
    )
  }

  const Frontside = ({entity}) => (
    <>
        <div style={{marginBottom: '1rem'}}>
          <GooglePlaceMap lat={entity.accounts[0].lat} lng={entity.accounts[0].lng} extraStyles={{borderRadius: '5px', border: '1px solid #dddddd'}} />
        </div>

        <Row style={{marginBottom: '1rem'}}>
          <Col xs="12">
            <h3>Accounts</h3>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Electric  <sub>({entity.accounts_by_commodity['Electric'].length})</sub></Accordion.Header>
                <Accordion.Body>
                  {
                      entity.electric_tracking_graph_iframe &&
                      <MinTrackingChart iframe={entity.electric_tracking_graph_iframe} />
                  }

                  <Table striped hover size="sm" responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th>DBA</th>
                        <th>Acct #</th>
                        <th>Service Address</th>
                        <th>Utility</th>
                        <th>Annual Usage</th>
                        <th>Current Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        entity.accounts_by_commodity['Electric'].map(account => (
                          <tr key={account.id}>
                            <td>
                              {
                                account.active > 0 ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>
                              }
                            </td>
                            <td>{account.doing_business_as}</td>
                            <td>{account.acct_number}</td>
                            <td>{account.account_full_address}</td>
                            <td>{account.utility_name}</td>
                            <td>{account.type.toLowerCase() == 'electric' ? (numberWithCommas(account.annual_kwh) + ' kWh') : (numberWithCommas(account.therms) + ' ccf')}</td>
                            <td>${account.current_rate}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Natural Gas <sub>({entity.accounts_by_commodity['Natural Gas'].length})</sub></Accordion.Header>
                <Accordion.Body>
                  {
                      entity.ng_tracking_graph_iframe &&
                      <MinTrackingChart iframe={entity.ng_tracking_graph_iframe} />
                  }

                  <Table striped hover size="sm" responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th>DBA</th>
                        <th>Acct #</th>
                        <th>Service Address</th>
                        <th>Utility</th>
                        <th>Annual Usage</th>
                        <th>Current Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        entity.accounts_by_commodity['Natural Gas'].map(account => (
                          <tr key={account.id}>
                            <td>
                              {
                                account.active > 0 ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>
                              }
                            </td>
                            <td>{account.doing_business_as}</td>
                            <td>{account.acct_number}</td>
                            <td>{account.account_full_address}</td>
                            <td>{account.utility_name}</td>
                            <td>{account.type.toLowerCase() == 'electric' ? (numberWithCommas(account.annual_kwh) + ' kWh') : (numberWithCommas(account.therms) + ' ccf')}</td>
                            <td>${account.current_rate}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        <Row style={{marginBottom: '1rem'}}>
          <Col xs="12">
            <h3>Contracts <Badge style={{float: 'right', cursor: 'pointer'}} bg="secondary" onClick={showTestBody}>Pricing History</Badge></h3>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Electric <sub>({entity.contracts_by_type['Electric'].length})</sub> <ProgressBar now={60} /></Accordion.Header>
                <Accordion.Body>
                  <Table striped hover size="sm" responsive>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Product Type</th>
                        <th>Supplier</th>
                        <th>Term</th>
                        <th>Days Left</th>
                        <th>Date Signed</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Rate</th>
                        <th>Annual Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        entity.contracts_by_type['Electric'].map(contract => (
                          <tr key={contract.id} style={{backgroundColor: contract.days_left > 0 && contract.days_left <= 30 ? '#FF4136' : ''}}>
                            <td>
                              {
                                parseInt(contract.active) === 1 ? <Badge bg="success">Active</Badge> : (contract.end_date < moment().format('YYYY-MM-DD') ? <Badge bg="danger">Expired</Badge> : (parseInt(contract.active_trump) > 0 ? <Badge bg="danger">Inactive</Badge> : <Badge bg="info">Future</Badge>))
                              }
                            </td>
                            <td>{contract.product_type}</td>
                            <td>{contract.supplier}</td>
                            <td>{contract.term}</td>
                            <td>{contract.days_left}</td>
                            <td>{moment(contract.date_signed).format('M/D/Y')}</td>
                            <td>{moment(contract.start_date).format('M/D/Y')}</td>
                            <td>{moment(contract.end_date).format('M/D/Y')}</td>
                            <td>{"$" + contract.rate}</td>
                            <td>{contract.type.toLowerCase() == 'electric' ? (numberWithCommas(contract.total_usage) + ' kWh') : (numberWithCommas(contract.total_usage) + ' ccf')}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Natural Gas <sub>({entity.contracts_by_type['Natural Gas'].length})</sub></Accordion.Header>
                <Accordion.Body>
                  <Table striped hover size="sm" responsive>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Product Type</th>
                        <th>Supplier</th>
                        <th>Term</th>
                        <th>Days Left</th>
                        <th>Date Signed</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Rate</th>
                        <th>Annual Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        entity.contracts_by_type['Natural Gas'].map(contract => (
                          <tr key={contract.id} style={{backgroundColor: contract.days_left > 0 && contract.days_left <= 30 ? '#FF4136' : ''}}>
                            <td>
                              {
                                parseInt(contract.active) === 1 ? <Badge bg="success">Active</Badge> : (contract.end_date < moment().format('YYYY-MM-DD') ? <Badge bg="danger">Expired</Badge> : (parseInt(contract.active_trump) > 0 ? <Badge bg="danger">Inactive</Badge> : <Badge bg="info">Future</Badge>))
                              }
                            </td>
                            <td>{contract.product_type}</td>
                            <td>{contract.supplier}</td>
                            <td>{contract.term}</td>
                            <td>{contract.days_left}</td>
                            <td>{moment(contract.date_signed).format('M/D/Y')}</td>
                            <td>{moment(contract.start_date).format('M/D/Y')}</td>
                            <td>{moment(contract.end_date).format('M/D/Y')}</td>
                            <td>{"$" + contract.rate}</td>
                            <td>{contract.type.toLowerCase() == 'electric' ? (numberWithCommas(contract.total_usage) + ' kWh') : (numberWithCommas(contract.total_usage) + ' ccf')}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <h3>Uploads <sub>({entity.uploads.length})</sub></h3>
            <hr />
            {
              entity.uploads.length > 0 ?
              <Table striped hover size="sm" responsive>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {
                    entity.uploads.map(upload => (
                      <tr key={upload.id}>
                        <td>{upload.name}</td>
                        <td className="text-center" style={{color: "white"}}><Button size="sm" variant="info" href={upload.file_url} target="__blank">View</Button></td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table> :
              <p>No uploads</p>
            }
          </Col>
        </Row>
    </>
  )

  const Backside = () => (
    <Container>
      <Row>
        <Col>
          <h3 style={{background: 'none', border: 'none', padding: '0'}}><Badge style={{cursor: 'pointer'}} bg="secondary" onClick={showTestBody}>Back</Badge></h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <h3 style={{clear: 'both'}}>Electric</h3>
          <PricingHistoryChart entity={entity} type="Electric" />
        </Col>
        <Col xs={12} md={6}>
          <h3>Natural Gas </h3>
          <PricingHistoryChart entity={entity} type="Natural Gas" />
        </Col>
      </Row>
    </Container>
  )

  return entity ? (
    <Modal show={showModal} onHide={() => closeModal(entity.id)} size="xl" id="entity-modal" animation={false}>
      <Modal.Header style={{backgroundColor: '#eeeeee'}} closeButton>
        <Modal.Title>
          <div>{entity.legal_entity !== '' ? entity.legal_entity : 'Entity Details'}</div>
          <div style={{fontSize: '0.8rem', color: 'dddddd'}}>Agent: {entity.agent ? entity.agent : ''}</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          toggleBody ?
          <Frontside entity={entity} />
          : <Backside />
        }
      </Modal.Body>

      <Modal.Footer style={{backgroundColor: '#eeeeee'}}>
        <Button variant="secondary" onClick={() => closeModal(entity.id)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : <></>;
}

export default EntityModal;
